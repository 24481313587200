import { ReactNode } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { ViewSwitcher, ViewType } from "../view-switcher";
import { useSearchParams } from "react-router-dom";

export type LayoutSwitchProps = {
  views: {
    [key in ViewType]?: ReactNode;
  };
  filterBar?: ReactNode;
  defaultView?: ViewType;
  countOfCountCaption?: string; // e.g., Showing 12 of 47 Assets:
  noEntries?: string;
};

export function LayoutSwitch({
  filterBar,
  defaultView = ViewType.List,
  views,
  countOfCountCaption,
  noEntries,
}: LayoutSwitchProps) {
  const [searchParams] = useSearchParams();
  const selectedView = searchParams.get("view") || defaultView;

  const isSingleView = Object.keys(views).length === 1;

  const handleView = () => {
    if (noEntries) {
      return (
        <Grid item xs={12}>
          <Paper
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              py: 10,
            }}
          >
            <Typography color="text.secondary">{noEntries}</Typography>
          </Paper>
        </Grid>
      );
    }

    if (isSingleView) {
      return Object.values(views)[0];
    }

    if (selectedView === ViewType.List) {
      return views.list;
    }

    if (selectedView === ViewType.Keyboard) {
      return views.keyboard;
    }

    if (selectedView === ViewType.Graph) {
      return views.graph;
    }

    if (selectedView === ViewType.Cards) {
      return views.cards;
    }
  };

  return (
    <Grid container item spacing={5}>
      {filterBar && (
        <Grid item xs={12}>
          {filterBar}
        </Grid>
      )}
      {countOfCountCaption && (
        <Grid
          item
          xs="auto"
          sx={{ display: "flex", alignItems: "center", my: -2 }}
        >
          <Typography color="text.secondary">{countOfCountCaption}</Typography>
        </Grid>
      )}
      {!isSingleView && (
        <Grid item xs sx={{ textAlign: "right" }}>
          <ViewSwitcher
            views={Object.keys(views) as ViewType[]}
            defaultView={defaultView ?? ViewType.List}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        {handleView()}
      </Grid>
    </Grid>
  );
}
