import { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Link, Breadcrumbs, Grid, Box } from "@mui/material";
import { Space } from "~/lib/types";
import { HomeIcon } from "~/components/icons";
import { TestIamActionsQuery, useGetActivePoliciesQuery } from "~/operations";
import { Loading, LoadingFailed } from "~/components/loading";
import { usePolicies } from "./hooks/usePolicies";
import { LayoutSwitch } from "~/components/LayoutSwitch/LayoutSwitch";
import { List } from "./list";
import { FilterBar } from "~/pages/compliance/filter-bar";

type Props = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function Policies({ space, availablePermissions }: Props) {
  const { permissions, state, handle, orderBy, searchFilters } = usePolicies({
    availablePermissions,
    space,
  });

  const { data, error, loading, fetchMore, networkStatus } =
    useGetActivePoliciesQuery({
      variables: {
        input: {
          query: searchFilters.join(" "),
          scopeMrn: space.mrn,
          orderBy,
        },
      },
      fetchPolicy: "cache-and-network",
    });

  // show a loading spinner on initial load only
  if (networkStatus === 1) {
    return <Loading what="enabled policies" />;
  }

  // if fetching data fails, we'll show a failure
  if (error) {
    return <LoadingFailed what="enabled policies" />;
  }

  // Keep one copy of the original data and copy the data returned into a new array
  const activePolicies = data?.activePolicies?.edges || [];
  const totalPolicies = data?.activePolicies?.totalCount || 0;
  const pageInfo = data?.activePolicies?.pageInfo || undefined;

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Typography key={2}>Policies</Typography>,
  ];

  document.title = "Security · Mondoo";

  return (
    <Fragment>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Typography variant="h4" fontWeight={700} sx={{ mb: 3 }}>
        Policies
      </Typography>
      <Grid container spacing={3}>
        <LayoutSwitch
          filterBar={
            <FilterBar
              searchId="security-policies"
              placeholder="security_policies"
              searchFilters={searchFilters}
              handleFilterQuery={handle.filterQuery}
              mb={0}
            />
          }
          views={{
            list: (
              <Box>
                {loading ? (
                  <Loading what="enabled policies" />
                ) : (
                  <List
                    {...{
                      space,
                      handle,
                      state,
                      orderBy,
                      handleCheckAll: handle.checkAll,
                      permissions,
                      fetchMore,
                      totalPolicies,
                      activePolicies: activePolicies.slice(
                        state.pageItems.from,
                        state.pageItems.to,
                      ),
                      selectedItems: state.selectedItems,
                      PaginationProps: {
                        fetchMore,
                        setPageItems: state.setPageItems,
                        pageInfo,
                        totalCount: totalPolicies,
                      },
                    }}
                  />
                )}
              </Box>
            ),
          }}
        />
      </Grid>
    </Fragment>
  );
}
