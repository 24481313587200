import React from "react";
import { FormControl, TextField, Typography, useTheme } from "@mui/material";
import { Controller, UseFormRegister } from "react-hook-form";
import { useColorMode } from "~/providers/color-mode";
import {
  Control,
  FormState,
  UseFormGetFieldState,
} from "react-hook-form/dist/types/form";
import { CreateCasesFormInput } from "~/components/cases/components/CreateCaseModal/CreateCaseModal";
import {
  helperTextStyles,
  ValidationMessage,
} from "~/pages/integrations/validations/helpers";
import { FieldErrors } from "react-hook-form/dist/types/errors";

type CreateCaseModalProps = {
  hasCreateCasePermissions: boolean;
  register: UseFormRegister<CreateCasesFormInput>;
  control: Control<CreateCasesFormInput>;
  formState: FormState<CreateCasesFormInput>;
  getFieldState: UseFormGetFieldState<CreateCasesFormInput>;
  errors: FieldErrors<CreateCasesFormInput>;
  disabled: boolean;
};

export const CreateCaseGithubModal = ({
  hasCreateCasePermissions,
  control,
  register,
  formState,
  getFieldState,
  errors,
  disabled,
}: CreateCaseModalProps) => {
  const { mode } = useColorMode();
  const theme = useTheme();

  return (
    <>
      <FormControl fullWidth>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mb: 1, fontWeight: 700 }}
        >
          Organization
        </Typography>
        <Controller
          name="github.owner"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              disabled={disabled}
              sx={{
                background: theme.palette.code.background,
                borderRadius: 1,
                color: "text.primary",
                ...helperTextStyles,
              }}
              placeholder={"Your GitHub organization..."}
              error={
                getFieldState("github.owner").isTouched &&
                Boolean(errors.github?.owner)
              }
              helperText={
                Boolean(errors.github?.owner) &&
                getFieldState("github.owner").isTouched && (
                  <ValidationMessage error={errors.github?.owner} />
                )
              }
            />
          )}
        />
        <FormControl fullWidth sx={{ marginTop: 3 }}>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ mb: 1, fontWeight: 700 }}
          >
            Repository
          </Typography>
          <Controller
            name="github.repo"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                disabled={disabled}
                sx={{
                  background: theme.palette.code.background,
                  borderRadius: 1,
                  color: "text.primary",
                  ...helperTextStyles,
                }}
                placeholder={"Your GitHub repository..."}
                error={
                  getFieldState("github.repo").isTouched &&
                  Boolean(errors.github?.repo)
                }
                helperText={
                  Boolean(errors.github?.repo) &&
                  getFieldState("github.repo").isTouched && (
                    <ValidationMessage error={errors.github?.repo} />
                  )
                }
              />
            )}
          />
        </FormControl>
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mb: 1, fontWeight: 700 }}
        >
          Title
        </Typography>
        <TextField
          placeholder="Write a short description to identify the issue..."
          {...register("github.title", { required: true })}
          error={Boolean(formState.errors?.github?.title)}
          helperText={formState.errors?.github?.title?.message}
          disabled={!hasCreateCasePermissions}
          InputProps={{
            sx: {
              backgroundColor:
                mode === "light" ? "common.white" : "common.black",
              borderRadius: 1,
            },
          }}
          data-test-id="case-title-input"
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mb: 1, fontWeight: 700 }}
        >
          Description
          <Typography component="span" color="text.secondary">
            {" "}
            (generated and maintained by Mondoo)
          </Typography>
        </Typography>
        <TextField
          placeholder="Describe the issue..."
          multiline
          disabled
          rows={6}
          {...register("github.description", { required: true })}
          error={Boolean(formState.errors?.github?.description)}
          helperText={formState.errors?.github?.description?.message}
          InputProps={{
            sx: {
              borderRadius: 1,
            },
          }}
          data-test-id="case-description-input"
        />
      </FormControl>
      {/*<FormControl fullWidth sx={{ marginTop: 3 }}>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ mb: 1, fontWeight: 700 }}
            >
              Labels{" "}
              <Typography component="span" color="text.secondary">
                (Optional)
              </Typography>
            </Typography>
            <TextField
              placeholder="Add labels separated by commas..."
              {...register("labels")}
              error={Boolean(formState.errors.labels)}
              helperText={formState.errors.labels?.message}
              InputProps={{
                sx: {
                  backgroundColor:
                    mode === "light" ? "common.white" : "common.black",
                  borderRadius: 1,
                },
              }}
              data-test-id="justification-input"
            />
          </FormControl>*/}
    </>
  );
};
