import { Paper, Typography } from "@mui/material";
import { Flex } from "~/components/ui-library";
import { JiraIcon } from "~/components/icons/mondoo/integrations/jira-icon";
import { Link } from "react-router-dom";
import { CaseTicket } from "~/components/cases/types";
import { OpenInNew } from "@mui/icons-material";
import { TicketRefType } from "~/operations";
import SvgIcon from "@mui/material/SvgIcon";
import {
  AttachIcon,
  FlyingEnvelopeIcon,
  GithubIcon,
  GitLabColorIcon,
} from "~/components/icons";
import { ZendeskIcon } from "~/components/icons/mondoo/integrations/zendesk";

type CaseTicketsProps = {
  tickets: CaseTicket[];
};

export const CaseTickets = ({ tickets }: CaseTicketsProps) => {
  const ticketTypeIconMap: Record<TicketRefType, typeof SvgIcon> = {
    [TicketRefType.Zendesk]: ZendeskIcon,
    [TicketRefType.Jira]: JiraIcon,
    [TicketRefType.Github]: GithubIcon,
    [TicketRefType.Email]: FlyingEnvelopeIcon,
    [TicketRefType.Gitlab]: GitLabColorIcon,
    [TicketRefType.Unknown]: AttachIcon,
  };

  return (
    <Flex sx={{ width: "100%", gap: 1, flexDirection: "column", mt: 2 }}>
      {tickets.map(({ description, href, id, type }) => {
        const Icon = ticketTypeIconMap[type] || AttachIcon;

        return (
          <Paper sx={{ px: 3, py: 1, flex: "1 0 auto" }}>
            <Flex justifyContent="space-between">
              <Flex>
                <Icon sx={{ mr: 2 }} />
                <Typography fontWeight={700}>{id}</Typography>
                {description && (
                  <Typography color="text.secondary" sx={{ ml: 0.5 }}>
                    {`– ${description}`}
                  </Typography>
                )}
              </Flex>
              {href && (
                <Link
                  to={href}
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: "inline-flex" }}
                >
                  <OpenInNew color="primary" />
                </Link>
              )}
            </Flex>
          </Paper>
        );
      })}
    </Flex>
  );
};
