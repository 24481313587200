import React from "react";
import { FormControl, TextField, Typography, useTheme } from "@mui/material";
import { Controller, UseFormRegister } from "react-hook-form";
import { useColorMode } from "~/providers/color-mode";
import {
  Control,
  FormState,
  UseFormGetFieldState,
} from "react-hook-form/dist/types/form";
import { CreateCasesFormInput } from "~/components/cases/components/CreateCaseModal/CreateCaseModal";
import {
  helperTextStyles,
  ValidationMessage,
} from "~/pages/integrations/validations/helpers";
import { FieldErrors } from "react-hook-form/dist/types/errors";

type CreateCaseModalProps = {
  hasCreateCasePermissions: boolean;
  register: UseFormRegister<CreateCasesFormInput>;
  control: Control<CreateCasesFormInput>;
  formState: FormState<CreateCasesFormInput>;
  getFieldState: UseFormGetFieldState<CreateCasesFormInput>;
  errors: FieldErrors<CreateCasesFormInput>;
  disabled: boolean;
};

export const CreateCaseGitlabModal = ({
  hasCreateCasePermissions,
  control,
  register,
  formState,
  getFieldState,
  errors,
  disabled,
}: CreateCaseModalProps) => {
  const { mode } = useColorMode();
  const theme = useTheme();

  return (
    <>
      <FormControl fullWidth>
        <FormControl fullWidth sx={{ marginTop: 3 }}>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ mb: 1, fontWeight: 700 }}
          >
            Project
          </Typography>
          <Controller
            name="gitlab.project"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                disabled={disabled}
                sx={{
                  background: theme.palette.code.background,
                  borderRadius: 1,
                  color: "text.primary",
                  ...helperTextStyles,
                }}
                placeholder={"your-group/your-project ..."}
                error={
                  getFieldState("gitlab.project").isTouched &&
                  Boolean(errors.gitlab?.project)
                }
                helperText={
                  Boolean(errors.gitlab?.project) &&
                  getFieldState("gitlab.project").isTouched && (
                    <ValidationMessage error={errors.gitlab?.project} />
                  )
                }
              />
            )}
          />
        </FormControl>
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mb: 1, fontWeight: 700 }}
        >
          Title
        </Typography>
        <TextField
          placeholder="Write a short description to identify the issue..."
          {...register("gitlab.title", { required: true })}
          error={Boolean(formState.errors?.gitlab?.title)}
          helperText={formState.errors?.gitlab?.title?.message}
          disabled={!hasCreateCasePermissions}
          InputProps={{
            sx: {
              backgroundColor:
                mode === "light" ? "common.white" : "common.black",
              borderRadius: 1,
            },
          }}
          data-test-id="case-title-input"
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mb: 1, fontWeight: 700 }}
        >
          Description
          <Typography component="span" color="text.secondary">
            {" "}
            (generated and maintained by Mondoo)
          </Typography>
        </Typography>
        <TextField
          placeholder="Describe the issue..."
          multiline
          disabled
          rows={6}
          {...register("gitlab.description", { required: true })}
          error={Boolean(formState.errors?.gitlab?.description)}
          helperText={formState.errors?.gitlab?.description?.message}
          InputProps={{
            sx: {
              borderRadius: 1,
            },
          }}
          data-test-id="case-description-input"
        />
      </FormControl>
    </>
  );
};
