import { Fragment } from "react";
import { Alert, Breadcrumbs, Link, Typography } from "@mui/material";
import {
  Link as RouterLink,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { AwsIntegrationForm } from "./forms/aws";
import { OktaIntegrationForm } from "./forms/okta";
import { AzureIntegrationForm } from "./forms/azure";
import { AzureBlobIntegrationForm } from "./forms/azure-blob";
import { BigQueryIntegrationForm } from "./forms/bigquery";
import { PostgreSQLIntegrationForm } from "./forms/postgresql";
import { CloudSQLIntegrationForm } from "./forms/cloudsql";
import { SnowflakeIntegrationForm } from "./forms/snowflake";
import { S3IntegrationForm } from "./forms/s3";
import { S3CompatibleIntegrationForm } from "./forms/s3-compatible";
import { GcsBucketIntegrationForm } from "./forms/gcs-bucket";
import { GcpIntegrationForm } from "./forms/gcp";
import { Ms365IntegrationForm } from "./forms/ms365";
import { SlackIntegrationForm } from "./forms/slack";
import { GitlabIntegrationForm } from "./forms/gitlab";
import { GithubIntegrationForm } from "./forms/github";
import { GoogleWorkspaceIntegrationForm } from "./forms/google-workspace";
import { mapIntegrationRouteToTitle } from "./helpers";
import { Space } from "~/lib/types";
import { HomeIcon } from "~/components/icons";
import { useGetClientIntegrationQuery } from "~/operations";
import { JiraIntegrationForm } from "./forms/jira/jira";
import { OciIntegrationForm } from "./forms/oci";
import { DomainIntegrationForm } from "./forms/domain";
import { EmailIntegrationForm } from "~/pages/integrations/hosted-integrations/forms/email/email";
import { ZendeskIntegrationForm } from "./forms/zendesk/zendesk";
import { MsftDefenderIntegrationForm } from "./forms/msft-defender";
import { GithubIssuesIntegrationForm } from "./forms/github-issues/github-issues";
import { GitlabIssuesIntegrationForm } from "./forms/gitlab-issues/gitlab-issues";

type Props = {
  space: Space;
  availablePermissions: string[];
};

export function HostedIntegrationEdit({ space, availablePermissions }: Props) {
  const { integrationId, integrationTypeId } = useParams();
  const [searchParams] = useSearchParams();
  const integrationMrnParam = searchParams.get("integrationMrn");
  const mrn =
    integrationMrnParam ||
    `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;
  const { data, error, loading } = useGetClientIntegrationQuery({
    variables: { mrn },
  });
  const integrationTitle = mapIntegrationRouteToTitle(integrationTypeId);

  if (error) {
    return <LoadingFailedPage what={`${integrationTitle} integration`} />;
  }

  if (loading || !data?.clientIntegration) {
    return <LoadingPage what={`${integrationTitle} integration`} />;
  }

  const { integration } = data.clientIntegration;

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/integrations"
      component={RouterLink}
      to={`/space/integrations?spaceId=${space.id}`}
    >
      Integrations
    </Link>,
    <Link
      key={`/space/integrations/${integrationTypeId}`}
      component={RouterLink}
      to={`/space/integrations/${integrationTypeId}?spaceId=${space.id}`}
    >
      {integrationTitle}
    </Link>,
    <Link
      key={`/space/integrations/${integrationTypeId}/:integrationId`}
      component={RouterLink}
      to={`/space/integrations/${integrationTypeId}/${integrationId}?spaceId=${space.id}&integrationMrn=${integration.mrn}`}
    >
      {integration.name}
    </Link>,
    <Typography
      key={`/space/integrations/${integrationTypeId}/integrationId/edit`}
      color="text.primary"
    >
      Edit
    </Typography>,
  ];

  const getIntegrationForm = (
    integrationTypeId: string | undefined,
  ): JSX.Element => {
    if (!integrationTypeId)
      return <NoIntegrationsToEdit {...{ spaceId: space.id }} />;

    switch (integrationTypeId.toLowerCase()) {
      case "aws":
        return (
          <AwsIntegrationForm {...{ space, updateFlow: { integration } }} />
        );
      case "azure":
        return (
          <AzureIntegrationForm {...{ space, updateFlow: { integration } }} />
        );
      case "azure_blob":
        return (
          <AzureBlobIntegrationForm
            {...{ space, updateFlow: { integration } }}
          />
        );
      case "bigquery":
        return (
          <BigQueryIntegrationForm
            {...{ space, updateFlow: { integration } }}
          />
        );
      case "postgres":
        return (
          <PostgreSQLIntegrationForm
            {...{ space, updateFlow: { integration } }}
          />
        );
      case "cloudsql":
        return (
          <CloudSQLIntegrationForm
            {...{ space, updateFlow: { integration } }}
          />
        );
      case "snowflake":
        return (
          <SnowflakeIntegrationForm
            {...{ space, updateFlow: { integration } }}
          />
        );
      case "amazons3":
        return (
          <S3IntegrationForm {...{ space, updateFlow: { integration } }} />
        );
      case "amazon-s3-compatible":
        return (
          <S3CompatibleIntegrationForm
            {...{ space, updateFlow: { integration } }}
          />
        );
      case "defender":
        return (
          <MsftDefenderIntegrationForm
            {...{ space, updateFlow: { integration } }}
          />
        );
      case "host":
        return (
          <DomainIntegrationForm {...{ space, updateFlow: { integration } }} />
        );
      case "gcs_bucket":
        return (
          <GcsBucketIntegrationForm
            {...{ space, updateFlow: { integration } }}
          />
        );
      case "gcp":
        return (
          <GcpIntegrationForm {...{ space, updateFlow: { integration } }} />
        );
      case "ms365":
        return (
          <Ms365IntegrationForm {...{ space, updateFlow: { integration } }} />
        );
      case "okta":
        return (
          <OktaIntegrationForm {...{ space, updateFlow: { integration } }} />
        );
      case "oci":
        return (
          <OciIntegrationForm {...{ space, updateFlow: { integration } }} />
        );
      case "hosted_slack":
        return (
          <SlackIntegrationForm {...{ space, updateFlow: { integration } }} />
        );
      case "gitlab":
        return (
          <GitlabIntegrationForm {...{ space, updateFlow: { integration } }} />
        );
      case "github":
        return (
          <GithubIntegrationForm {...{ space, updateFlow: { integration } }} />
        );
      case "google_workspace":
        return (
          <GoogleWorkspaceIntegrationForm
            {...{ space, updateFlow: { integration } }}
          />
        );
      case "ticket_system_jira":
        return (
          <JiraIntegrationForm {...{ space, updateFlow: { integration } }} />
        );
      case "ticket_system_email":
        return (
          <EmailIntegrationForm {...{ space, updateFlow: { integration } }} />
        );
      case "ticket_system_zendesk":
        return (
          <ZendeskIntegrationForm {...{ space, updateFlow: { integration } }} />
        );

      case "ticket_system_github":
        return (
          <GithubIssuesIntegrationForm
            {...{ space, updateFlow: { integration } }}
          />
        );

      case "ticket_system_gitlab":
        return (
          <GitlabIssuesIntegrationForm
            {...{ space, updateFlow: { integration } }}
          />
        );
      default:
        return <NoIntegrationsToEdit {...{ spaceId: space.id }} />;
    }
  };

  document.title = `Edit · ${integration.name} · ${integrationTitle} · Mondoo`;

  return (
    <Fragment>
      <Breadcrumbs sx={{ mb: 5 }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      {getIntegrationForm(integrationTypeId)}
    </Fragment>
  );
}

const NoIntegrationsToEdit = ({ spaceId }: { spaceId: Space["id"] }) => {
  return (
    <Alert variant="outlined" severity="error">
      <Typography>
        Oops, something may have gone wrong or you have no integration to edit.
      </Typography>
      <Typography variant="caption">
        You may try reloading the page or navigating to view{" "}
        <Link
          component={RouterLink}
          to={`/space/integrations?spaceId=${spaceId}`}
        >
          all of your integrations
        </Link>
        .
      </Typography>
    </Alert>
  );
};
