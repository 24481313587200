import { Fragment, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useAssetOutlet } from "~/pages/inventory/asset";
import {
  LoadAssetListPoliciesQuery,
  TestIamActionsQuery,
  useLoadAssetListPoliciesQuery,
} from "~/operations";
import { FilterBar } from "~/pages/compliance/filter-bar";
import { useSearch } from "~/components/search/useSearch";
import { AssetPolicyDistributionCard } from "./assetPolicyDistributionCard/assetPolicyDistributionCard";
import { AssetPolicyOverviewCard } from "./assetPolicyOverviewCard/assetPolicyOverviewCard";
import { PolicyCardGrid } from "../components/Policies";
import { ChartData } from "~/components/charts/donut-chart";
import {
  INITIAL_PAGE_RANGE,
  Pagination,
  PaginationRange,
} from "~/components/pagination";
import { Loading, LoadingFailed } from "~/components/loading";
import { useSearchParams } from "react-router-dom";
import { EmptyState } from "~/components/empty-state/empty-state";

export type AssetReport = Extract<
  NonNullable<LoadAssetListPoliciesQuery["assetReport"]>,
  { __typename: "AssetReport" }
>;

type Props = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
  isCicd?: boolean;
};

export function AssetPoliciesTab({
  availablePermissions,
  isCicd = false,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { assetMrn, assetReport, space } = useAssetOutlet();
  const { handleFilterQuery, searchFilters } = useSearch();
  const [pageItems, setPageItems] =
    useState<PaginationRange>(INITIAL_PAGE_RANGE);
  // gathering the current filters from the URL
  let url = new URL(window.location.href);
  let gradeFilters = url.searchParams.get("grades")?.split(",") || [];
  let categoryFilter = url.searchParams.get("categories")?.split(",") || [];

  const hasFilters =
    gradeFilters.length > 0 ||
    categoryFilter.length > 0 ||
    searchFilters.length > 0;

  const { data, error, networkStatus } = useLoadAssetListPoliciesQuery({
    skip: !assetMrn,
    variables: {
      input: {
        assetMrn,
      },
      first: 50,
      after: null,
      filter: {
        categories: categoryFilter,
        grades: gradeFilters,
        query: searchFilters.join(),
      },
    },
  });

  const policiesFetching = networkStatus === 1;
  const policiesRefetching = networkStatus === 2;
  const policiesLoading = policiesFetching || policiesRefetching;
  const assetReportUnion = data?.assetReport;
  const assetReportPolicies =
    assetReportUnion?.__typename === "AssetReport"
      ? assetReportUnion.listPolicies
      : undefined;
  const policyEdges = assetReportPolicies?.edges ?? [];
  const policyTotalCount = assetReportPolicies?.totalCount ?? 0;
  const hasPolicies = !policiesFetching && (hasFilters || policyTotalCount > 0);

  if (error && !assetReportPolicies) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: 12,
        }}
      >
        <LoadingFailed what="Asset Policies" />
      </Box>
    );
  }

  // set search params for the page size but do not overwrite the other search params
  const updateSearchParams = (param: string, value: string) => {
    let updatedSearchParams = new URL(window.location.href).searchParams;
    if (value === "") {
      updatedSearchParams.delete(param);
      setSearchParams(updatedSearchParams.toString());
    } else {
      updatedSearchParams.set(param, value.toString());
    }
    setSearchParams(updatedSearchParams.toString());
  };

  const handleCategoryFilterClick = (category: string) => {
    // If the category is "total", clear all filters
    // "total" is the same as "all categories"
    if (category === "total") {
      return updateSearchParams("categories", "");
    }
    if (categoryFilter.includes(category)) {
      updateSearchParams(
        "categories",
        categoryFilter.filter((c) => c !== category).join(","),
      );
    } else {
      updateSearchParams("categories", [...categoryFilter, category].join(","));
    }
  };

  const handleGradeFilterClick = (data: ChartData) => {
    let url = new URL(window.location.href);
    let filters = url.searchParams.get("grades")?.split(",") || [];

    let grade = data.label;

    if (filters.includes(grade)) {
      updateSearchParams(
        "grades",
        filters.filter((c) => c !== grade).join(","),
      );
    } else {
      updateSearchParams("grades", [...filters, grade].join(","));
    }
  };

  return (
    <Fragment>
      {hasPolicies && (
        <Grid container item xs={12} columnSpacing={3} rowGap={3} my={3}>
          {/* Policy Overview */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              maxWidth: {
                md: 335,
              },
            }}
          >
            {assetReport.listPolicies && (
              <AssetPolicyOverviewCard
                policies={assetReport.listPolicies}
                sizes={{ xs: 12 }}
                handleGradeFilterClick={handleGradeFilterClick}
                selectedGrades={searchParams.get("grades")?.split(",") || []}
                assetMrn={assetMrn}
              />
            )}
          </Grid>
          {/* Policy Distribution */}
          <Grid item xs={12} md>
            <AssetPolicyDistributionCard
              assetMrn={assetMrn}
              sizes={{ xs: 12 }}
              handleCategoryFilterClick={handleCategoryFilterClick}
              selectedCategories={categoryFilter}
            />
          </Grid>
        </Grid>
      )}
      {/* Filter Search Bar */}
      {hasPolicies && (
        <Grid item xs={12}>
          <FilterBar
            searchId="inventory-asset-policies"
            placeholder="inventory_asset_policies"
            searchFilters={searchFilters}
            handleFilterQuery={handleFilterQuery}
            mb={0}
          />
        </Grid>
      )}

      {policiesLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            placeContent: "center",
            py: 10,
          }}
        >
          <Loading what="Asset Policies" />
        </Box>
      ) : (
        <Fragment>
          {!hasPolicies && (
            <Box my={3}>
              <EmptyState contentType="asset-policies-tab" space={space} />
            </Box>
          )}

          {/* Policy Cards */}
          {hasPolicies && (
            <Fragment>
              <PolicyCardGrid
                policies={policyEdges.slice(pageItems.from, pageItems.to)}
                availablePermissions={availablePermissions}
                isCicd={isCicd}
              />
              <Pagination
                totalCount={policyTotalCount}
                setPageItems={setPageItems}
                customPageSizes={[6, 18, 24]}
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
