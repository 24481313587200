import { SyntheticEvent, useState } from "react";
import { IamActions } from "~/lib/iam";
import { Space } from "~/lib/types";
import {
  ActivePolicyOrder,
  ActivePolicyOrderField,
  GetActivePoliciesDocument,
  OrderDirection,
  PolicyAction,
  TestIamActionsQuery,
  useAssignPolicyMutation,
  useUnassignPolicyMutation,
} from "~/operations";
import { useNavigate } from "react-router-dom";
import { ActivePolicyEdge, SelectedPolicies, SelectedPolicy } from "../types";
import { enqueueSnackbar } from "notistack";
import { pluralize } from "~/lib/pluralize";
import { useSort } from "~/pages/inventory/hooks/useSort";
import { useSearch } from "~/components/search/useSearch";
import { INITIAL_PAGE_RANGE } from "~/components/pagination";

type UsePoliciesProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function usePolicies({ availablePermissions, space }: UsePoliciesProps) {
  const navigate = useNavigate();
  const { handleFilterQuery, searchFilters } = useSearch();
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const [selectedItems, setSelectedItems] = useState<SelectedPolicies>([]);
  const { handleSortClick, orderBy } = useSort<ActivePolicyOrder>({
    defaultSort: {
      direction: OrderDirection.Desc,
      field: ActivePolicyOrderField.AffectedAssets,
    },
    validFields: [
      ActivePolicyOrderField.Name,
      ActivePolicyOrderField.AffectedAssets,
    ],
  });

  // Whether the user has permission to disable policies from this view
  const hasEditPolicyPermission =
    availablePermissions != null &&
    [
      IamActions.POLICY_MARKETPLACE_SETPOLICYBUNDLE,
      IamActions.ACTION_CNSPEC_POLICY_POLICYHUB_SETBUNDLE,
    ].some((permission) => availablePermissions?.includes(permission));

  const [disablePolicies] = useUnassignPolicyMutation({
    variables: {
      input: {
        policyMrns: selectedItems.map((policy) => policy.policyMrn),
        assetMrn: space.mrn,
      },
    },
    refetchQueries: [
      {
        query: GetActivePoliciesDocument,
        variables: {
          input: {
            scopeMrn: space.mrn,
          },
        },
      },
    ],
  });

  const [assignPolicy] = useAssignPolicyMutation();

  // Handle when a user checks a single policy
  const handleCheck = (_e: SyntheticEvent, policy: SelectedPolicy) => {
    let selectedItemsCopy = [...selectedItems];
    const alreadySelected = selectedItemsCopy.find(
      (item) => item.policyMrn === policy.policyMrn,
    );
    if (alreadySelected) {
      selectedItemsCopy = selectedItemsCopy.filter(
        (i) => i.policyMrn !== policy.policyMrn,
      );
    } else {
      selectedItemsCopy.push(policy);
    }

    setSelectedItems(selectedItemsCopy);
  };

  // handle action when a user clicks the indeterminate/select all
  // checkbox. If at least one query is selected, we deselect all the options.
  // If all are unselected, we will select all.
  const handleCheckAll = (activePolicies: ActivePolicyEdge[]) => {
    let newSelections: SelectedPolicies = [];

    if (selectedItems.length < 1) {
      newSelections = [
        ...activePolicies.map((edge) => ({
          policyMrn: String(edge.node?.mrn),
          action: edge.node?.action,
        })),
      ];
    }

    setSelectedItems(newSelections);
  };

  // Uncheck all the selected policies
  const unCheckAll = () => {
    setSelectedItems([]);
  };

  // Handle action to disable any selected policies.
  const handleDisabling = () => {
    const totalPolicies = selectedItems.length;
    //handle an edge case if someone is able to attempt to
    //somehow disable policies with none selected
    if (totalPolicies < 1) {
      return enqueueSnackbar(
        "There are no policies selected to disable. Please make a selection and try again",
        {
          variant: "error",
        },
      );
    }

    try {
      disablePolicies();
      unCheckAll();
      enqueueSnackbar(
        `Successfully disabled ${totalPolicies} ${pluralize(
          "policy",
          totalPolicies,
        )}`,
        { variant: "success" },
      );
    } catch (error) {
      enqueueSnackbar(
        `Failed to disable ${pluralize("policy", totalPolicies)}`,
        {
          variant: "error",
        },
      );
    }
  };

  // handle placing policies in preview mode
  const handlePlaceInPreview = () => {
    handleAssignPolicy(PolicyAction.Ignore);
  };

  const handleEnable = () => {
    handleAssignPolicy(PolicyAction.Active);
  };

  const hasSelectedEnabledPolicy = selectedItems.some(
    (i) => i.action === PolicyAction.Active,
  );

  const hasSelectedPreviewedPolicy = selectedItems.some(
    (i) => i.action === PolicyAction.Ignore,
  );

  const hasSelectedBothEnabledAndPreviewedPolicies =
    hasSelectedEnabledPolicy && hasSelectedPreviewedPolicy;

  // handle enabling / setting policies in preview
  const handleAssignPolicy = async (action: PolicyAction) => {
    const totalPolicies = selectedItems.length;
    try {
      await assignPolicy({
        variables: {
          input: {
            action,
            policyMrns: selectedItems.map((i) => i.policyMrn),
            assetMrn: space.mrn,
          },
        },
        refetchQueries: [
          {
            query: GetActivePoliciesDocument,
            variables: {
              input: {
                scopeMrn: space.mrn,
              },
            },
          },
        ],
      });
      unCheckAll();
      switch (action) {
        case PolicyAction.Ignore:
          enqueueSnackbar(
            `Successfully placed ${totalPolicies} ${pluralize(
              "policy",
              totalPolicies,
            )} in preview`,
            { variant: "success" },
          );
          break;
        case PolicyAction.Active:
          enqueueSnackbar(
            `Successfully enabled ${totalPolicies} ${pluralize(
              "policy",
              totalPolicies,
            )}`,
            { variant: "success" },
          );
          break;
      }
    } catch (error) {
      enqueueSnackbar(
        `Failed to place ${pluralize("policy", totalPolicies)} in preview`,
        {
          variant: "error",
        },
      );
    }
  };

  const handleNavigation = (href: string) => {
    navigate(href);
  };

  return {
    handle: {
      filterQuery: handleFilterQuery,
      sortClick: handleSortClick,
      disablePolicies: handleDisabling,
      unCheckAll,
      check: handleCheck,
      checkAll: handleCheckAll,
      navigate: handleNavigation,
      previewPolicies: handlePlaceInPreview,
      enablePolicies: handleEnable,
    },
    state: {
      hasSelectedBothEnabledAndPreviewedPolicies,
      hasSelectedEnabledPolicy,
      hasSelectedPreviewedPolicy,
      pageItems,
      setPageItems,
      selectedItems,
      setSelectedItems,
    },
    permissions: {
      edit: hasEditPolicyPermission,
    },
    orderBy,
    searchFilters,
  };
}
