import { useEffect } from "react";
import {
  alpha,
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { CheckIcon, ChevronRightIcon, CloseIcon } from "~/components/icons";
import { Command } from "~/components/guides/components";
import { Space } from "~/lib/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  AwsConfigurationOptions,
  AwsConfigurationOptionsInput,
  AwsEventPattern,
  ClientIntegrationType,
  GetIntegrationsSummaryDocument,
  HostedAwsConfigurationOptions,
  HostedAwsConfigurationOptionsInput,
  InputMaybe,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { SnackbarKey, useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { getError } from "~/lib/handle-error";
import { helperTextStyles, ValidationMessage } from "../../validations/helpers";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import useGenerateIntegrationName from "../../utils/useGenerateIntegrationName";
import { UpdateFlowData } from "../../types";
import { ContentFlag } from "~/components/content-flag";
import { AWS_REGIONS, stackUrl } from "~/pages/integration-setup";
import { isFeatureEnabled } from "~/login/features";
import { Validations } from "~/lib/validations";

const ec2StateChangeEvent =
  "EC2:aws.ec2:EC2 Instance State-change Notification";
const signinAccountEvent =
  "ACCOUNT:aws.signin:AWS Console Sign In via CloudTrail";

type AwsHostedFormInput = {
  authMethod: "key" | "arn";
  accessKeyId: string;
  secretAccessKey: string;
  arn: string;
  externalId: string;
};

type AwsServerlessFormInput = {
  installMethod: "organization" | "account";
  region: string;
  vpcType: "default" | "mondoo";
  vpcCidr: string;
  cronScanInHours: number;
  discoverEc2Instances: boolean;
  triggerOnInstanceStateChange: boolean;
  useSsm: boolean;
  useEc2InstanceConnect: boolean;
  useEbsVolumeScanning: boolean;
  filterInstanceIds: string;
  filterRegions: string;
  filterTags: string;
  discoverEcsContainers: boolean;
  discoverEcrImages: boolean;
  v2Template: boolean;
};

type AwsFormInput = {
  integrationName: string;
  integrationType: "hosted" | "serverless" | "unknown";
  hosted: AwsHostedFormInput;
  serverless: AwsServerlessFormInput;
};

const hostedDefaultValues: AwsHostedFormInput = {
  authMethod: "key",
  accessKeyId: "",
  secretAccessKey: "",
  arn: "",
  externalId: "",
};

const serverlessDefaultValues: AwsServerlessFormInput = {
  installMethod: "account",
  region: "us-east-1",
  vpcCidr: "196.78.0.0/24",
  vpcType: "default",
  cronScanInHours: 12,
  discoverEc2Instances: true,
  triggerOnInstanceStateChange: true,
  useSsm: true,
  useEc2InstanceConnect: true,
  useEbsVolumeScanning: false,
  filterInstanceIds: "",
  filterRegions: "",
  filterTags: "",
  discoverEcsContainers: false,
  discoverEcrImages: false,
  v2Template: false,
};

const defaultValues: AwsFormInput = {
  integrationName: "",
  integrationType: "unknown",
  hosted: hostedDefaultValues,
  serverless: serverlessDefaultValues,
};

export function AwsIntegrationForm({
  space,
  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const roleAuthEnabled = isFeatureEnabled("Hosted AWS - Role Auth");

  const defaultIntegrationName = useGenerateIntegrationName({ space });
  const configOptions = updateFlow?.integration.configurationOptions;
  const configTypename = configOptions?.__typename;
  const defaultIntegrationType =
    configTypename === "AWSConfigurationOptions"
      ? "serverless"
      : configTypename === "HostedAwsConfigurationOptions"
        ? "hosted"
        : "unknown";

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getFieldState,
    formState: { errors, isValid },
  } = useForm<AwsFormInput>({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
      integrationType: defaultIntegrationType,
    },
  });

  const [createIntegration] = useCreateClientIntegrationMutation({
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery();

  const [
    integrationType,
    hostedAuthMethod,
    serverlessVpcType,
    serverlessDiscoverEc2Instances,
  ] = watch([
    "integrationType",
    "hosted.authMethod",
    "serverless.vpcType",
    "serverless.discoverEc2Instances",
  ]);

  useEffect(() => {
    if (configOptions && integrationType !== "unknown") {
      const hostedValues = (
        configOptions: HostedAwsConfigurationOptions,
      ): Partial<AwsHostedFormInput> => {
        return {
          authMethod: configOptions.role ? "arn" : "key",
          accessKeyId: configOptions.accessKeyId || "",
          arn: configOptions.role || "",
        };
      };

      const serverlessValues = (
        configOptions: AwsConfigurationOptions,
      ): Partial<AwsServerlessFormInput> => {
        const scanConfig = configOptions.scanConfiguration;
        const { ec2ScanOptions, eventScanTriggers, vpcConfiguration } =
          scanConfig;
        const eventTriggers = eventScanTriggers?.flatMap((t) => t ?? []) || [];

        const hasEventTrigger = (
          eventName: string,
          eventScanTriggers: AwsEventPattern[],
        ) => {
          const [scanType, eventSource, eventDetailType] = eventName.split(":");
          return eventScanTriggers.some(
            (t) =>
              t.scanType === scanType &&
              t.eventSource === eventSource &&
              t.eventDetailType === eventDetailType,
          );
        };

        const tagsFilterValue = (tagsFilter: { [key: string]: string }) => {
          return Object.entries(tagsFilter)
            .map((tag) => tag.join(":"))
            .join(", ");
        };

        return {
          installMethod: configOptions.isOrganization
            ? "organization"
            : "account",
          region: configOptions.region,
          vpcType: vpcConfiguration?.useMondooVPC ? "mondoo" : "default",
          vpcCidr: vpcConfiguration?.CIDR || "196.78.0.0/24",
          v2Template: true,
          cronScanInHours: scanConfig.cronScaninHours,
          discoverEc2Instances: scanConfig.ec2Scan,
          triggerOnInstanceStateChange: hasEventTrigger(
            ec2StateChangeEvent,
            eventTriggers,
          ),
          useSsm: ec2ScanOptions.ssm,
          useEc2InstanceConnect: ec2ScanOptions.instanceConnect,
          useEbsVolumeScanning: ec2ScanOptions.ebsVolumeScan,
          filterInstanceIds: ec2ScanOptions.instanceIdsFilter?.join(", ") || "",
          filterRegions: ec2ScanOptions.regionsFilter?.join(", ") || "",
          filterTags: tagsFilterValue(ec2ScanOptions.tagsFilter || {}),
          discoverEcsContainers: scanConfig.ecsScan,
          discoverEcrImages: scanConfig.ecrScan,
        };
      };

      reset({
        integrationName: updateFlow.integration.name,
        integrationType: integrationType,
        ...(configTypename === "HostedAwsConfigurationOptions"
          ? { hosted: hostedValues(configOptions) }
          : {}),
        ...(configTypename === "AWSConfigurationOptions"
          ? { serverless: serverlessValues(configOptions) }
          : {}),
      });
    }
  }, []);

  const hostedInput = (
    data: AwsHostedFormInput,
  ): InputMaybe<HostedAwsConfigurationOptionsInput> => {
    const { accessKeyId, secretAccessKey } = data;
    const keyCredential =
      accessKeyId && secretAccessKey
        ? { accessKeyId, secretAccessKey }
        : undefined;
    const { arn, externalId } = data;
    const roleCredential = arn ? { role: arn, externalId } : undefined;
    return {
      ...(data.authMethod === "key" ? { keyCredential } : { roleCredential }),
    };
  };

  const serverlessInput = (
    data: AwsServerlessFormInput,
  ): InputMaybe<AwsConfigurationOptionsInput> => {
    const eventPattern = (eventName: string) => {
      const [scanType, eventSource, eventDetailType] = eventName.split(":");
      return { scanType, eventSource, eventDetailType };
    };

    const eventScanTriggers = [
      data.triggerOnInstanceStateChange
        ? eventPattern(ec2StateChangeEvent)
        : undefined,
    ].flatMap((t) => t ?? []);

    const tagsFilter = data.filterTags
      .split(",")
      .map((s) => s.trim())
      .reduce(
        (a, c) => {
          const [k, v] = c.split(":");
          if (k && v) {
            a[k] = v;
          }
          return a;
        },
        {} as { [k: string]: string },
      );

    return {
      isOrganization: data.installMethod === "organization",
      region: data.region,
      v2Template: true,
      scanConfiguration: {
        ec2Scan: data.discoverEc2Instances,
        ecrScan: data.discoverEcrImages,
        ecsScan: data.discoverEcsContainers,
        cronScaninHours: data.cronScanInHours,
        eventScanTriggers,
        ec2ScanOptions: {
          ssm: data.useSsm,
          instanceConnect: data.useEc2InstanceConnect,
          instanceIdsFilter: data.filterInstanceIds
            .split(",")
            .map((s) => s.trim()),
          regionsFilter: data.filterRegions.split(",").map((s) => s.trim()),
          tagsFilter,
          ebsVolumeScan: data.useEbsVolumeScanning,
        },
        vpcConfiguration: {
          useDefaultVPC: data.vpcType === "default",
          useMondooVPC: data.vpcType === "mondoo",
          CIDR: data.vpcCidr,
        },
      },
    };
  };

  const onSubmit: SubmitHandler<AwsFormInput> = async (data) => {
    try {
      if (updateFlow) {
        const { mrn } = updateFlow.integration;
        const id = mrn.split("/").pop();
        await updateIntegration({
          variables: {
            input: {
              mrn,
              name: data.integrationName,
              type:
                data.integrationType === "hosted"
                  ? ClientIntegrationType.AwsHosted
                  : ClientIntegrationType.Aws,
              configurationOptions: {
                ...(data.integrationType === "hosted"
                  ? { awsHostedConfigurationOptions: hostedInput(data.hosted) }
                  : {}),
                ...(data.integrationType === "serverless"
                  ? {
                      awsConfigurationOptions: serverlessInput(data.serverless),
                    }
                  : {}),
              },
            },
          },
        });

        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });

        navigate(
          `/space/integrations/aws/${id}?spaceId=${space.id}&integrationMrn=${mrn}`,
        );
      } else {
        const longLivedToken =
          data.integrationType === "serverless" &&
          data.serverless.installMethod === "organization";

        const result = await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              type:
                data.integrationType === "hosted"
                  ? ClientIntegrationType.AwsHosted
                  : ClientIntegrationType.Aws,
              name: data.integrationName,
              longLivedToken,
              configurationOptions: {
                ...(data.integrationType === "hosted"
                  ? { awsHostedConfigurationOptions: hostedInput(data.hosted) }
                  : {}),
                ...(data.integrationType === "serverless"
                  ? {
                      awsConfigurationOptions: serverlessInput(data.serverless),
                    }
                  : {}),
              },
            },
          },
        });
        const integration = result.data?.createClientIntegration.integration;
        if (!integration) {
          throw "Failed to create AWS integration. No result data.";
        }
        const { mrn, token, configurationOptions } = integration;
        const id = mrn.split("/").pop();

        if (
          configurationOptions?.__typename !== "AWSConfigurationOptions" &&
          configurationOptions?.__typename !== "HostedAwsConfigurationOptions"
        ) {
          throw "Failed to create AWS integration. No config data.";
        }

        if (configurationOptions.__typename === "AWSConfigurationOptions") {
          const {
            cloudFormationTemplateUrl,
            snsEndpoint,
            originAWSAccountId,
            region,
            isOrganization,
          } = configurationOptions;
          const msg = (
            <span>
              Successfully added AWS integration.
              <br />
              <a
                style={{ textDecoration: "underline" }}
                href={stackUrl(
                  cloudFormationTemplateUrl,
                  originAWSAccountId,
                  region,
                  mrn,
                  token,
                  snsEndpoint,
                  isOrganization,
                )}
                target="_blank"
              >
                Navigate to the AWS Console
              </a>{" "}
              to complete the setup process.
            </span>
          );
          const action = (key: SnackbarKey) => (
            <IconButton
              size="small"
              aria-label="close"
              onClick={() => closeSnackbar(key)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          );

          enqueueSnackbar(msg, {
            persist: true,
            variant: "success",
            action,
          });
        }

        if (
          configurationOptions.__typename === "HostedAwsConfigurationOptions"
        ) {
          enqueueSnackbar("Successfully added AWS integration.", {
            variant: "success",
          });

          await triggerClientIntegrationScan({
            variables: { input: { type: ActionType.RunScan, mrn } },
          });
        }

        navigate(
          `/space/integrations/aws/${id}?spaceId=${space.id}&integrationMrn=${mrn}`,
        );
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  const actionText = (isEnabled: boolean) => (isEnabled ? "On" : "Off");

  // Step 1
  const formContent = (
    <>
      <Box pb={4}>
        <Command
          number={1}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Would you like to add a Mondoo-hosted or serverless integration?
        </Command>
        <Box sx={{ mt: 3 }}>
          <Controller
            key="integrationType"
            name="integrationType"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Box>
                  <FormControlLabel
                    value="hosted"
                    control={<Radio />}
                    label="Mondoo-hosted Integration"
                    disabled={Boolean(updateFlow)}
                  />
                  <Typography
                    variant="caption"
                    display="block"
                    color="text.secondary"
                    sx={{ pl: 4, mt: -1 }}
                  >
                    Scan AWS accounts directly from Mondoo Platform without
                    installing an agent in AWS.
                  </Typography>
                </Box>
                <Box>
                  <FormControlLabel
                    value="serverless"
                    control={<Radio />}
                    label="Serverless Integration"
                    disabled={Boolean(updateFlow)}
                  />
                  <Typography
                    variant="caption"
                    display="block"
                    color="text.secondary"
                    sx={{ pl: 4, mt: -1 }}
                  >
                    Scan AWS accounts and organizations with our secure,
                    Lambda-based scanning agent.
                  </Typography>
                </Box>
              </RadioGroup>
            )}
          />
        </Box>
      </Box>
      <Box pb={4}>
        <Command
          number={2}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Choose an integration name
        </Command>
        <Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 2, mt: 2 }}
          >
            Please choose a descriptive name that lets you easily identify your
            integration. If you don't enter a name, Mondoo uses the account ID.
          </Typography>
          <Controller
            name="integrationName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                sx={{
                  ".MuiInputBase-root": {
                    background: theme.palette.code.background,
                  },
                  borderRadius: 1,
                  color: "text.primary",
                  ...helperTextStyles,
                }}
                placeholder="Your integration name..."
                error={Boolean(errors.integrationName)}
                helperText={
                  Boolean(errors.integrationName) && (
                    <ValidationMessage error={errors.integrationName} />
                  )
                }
              />
            )}
          />
        </Box>
      </Box>
    </>
  );

  const chooseContent = (
    <Box>
      <Box sx={{ my: 4 }}>
        <Command
          number={1}
          options={{
            fontSize: { xs: 24 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Would you like to add a Mondoo-hosted or serverless integration?
        </Command>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,

          ".mode-detail": {
            backgroundColor: "background.paper",
            overflow: "hidden",
            p: 2,
            borderRadius: 1,
            border: "1px solid",
            borderColor: "transparent",

            ".mode-detail-name": {
              fontSize: 24,
              fontWeight: 700,
              textTransform: "uppercase",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            },

            ".mode-detail-description": {
              color: "text.secondary",
              my: 2,
            },

            "&.highlighted": {
              backgroundColor: "background.light",
              borderColor: "primary.main",
            },
          },
          ".pro-con": {
            borderRadius: 1,
            p: 2,
            height: "100%",

            ".pro-con-header": {
              fontSize: 16,
              fontWeight: 700,
              textTransform: "uppercase",
              mb: 1,
            },

            ".pro-con-list-item": { alignItems: "flex-start" },

            ".pro-con-icon": { minWidth: 0, pr: 1, my: 0.65 },

            "&.pros": {
              backgroundColor: alpha(theme.palette.none.light, 0.1),

              ".pro-con-header": {
                color: theme.palette.none.light,
              },

              ".pro-con-icon": { color: theme.palette.none.light },
            },

            "&.cons": {
              backgroundColor: alpha(theme.palette.critical.light, 0.1),

              ".pro-con-header": {
                color: theme.palette.critical.light,
              },

              ".pro-con-icon": { color: theme.palette.critical.light },
            },
          },
          strong: {
            textTransform: "uppercase",
          },
        }}
      >
        <Box className="mode-detail highlighted">
          <Grid container spacing={2}>
            <Grid item sm={12} md={4}>
              <Typography className="mode-detail-name">
                Mondoo-hosted
                <ContentFlag flag="recommended" color="recommended" />
              </Typography>
              <Typography className="mode-detail-description">
                Mondoo-hosted integrations scan AWS accounts directly from
                Mondoo Platform without installing an agent in AWS.{" "}
                <Link
                  href="https://mondoo.com/docs/platform/infra/cloud/aws/hosted/integration-hosted/"
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  Learn more.
                </Link>
              </Typography>
              <Button
                className="mode-detail-button"
                color="primary"
                variant="contained"
                endIcon={<ChevronRightIcon fontSize="inherit" />}
                onClick={() => setValue("integrationType", "hosted")}
              >
                Select Mondoo-hosted integration
              </Button>
            </Grid>
            <Grid item sm={12} md={4}>
              <Box className="pro-con pros">
                <Typography className="pro-con-header">Pros</Typography>
                <List className="pro-con-list" dense disablePadding>
                  <ListItem className="pro-con-list-item" disableGutters>
                    <ListItemIcon className="pro-con-icon">
                      <CheckIcon fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <>
                          <strong>Nothing to install:</strong> Scan your
                          infrastructure without an agent installation
                        </>
                      }
                    />
                  </ListItem>
                  <ListItem className="pro-con-list-item" disableGutters>
                    <ListItemIcon className="pro-con-icon">
                      <CheckIcon fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <>
                          <strong>Low cost:</strong> No added infrastructure
                          cost
                        </>
                      }
                    />
                  </ListItem>
                  <ListItem className="pro-con-list-item" disableGutters>
                    <ListItemIcon className="pro-con-icon">
                      <CheckIcon fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <>
                          <strong>Simple setup:</strong> Get started quickly
                          without deploying a Lambda function
                        </>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid item sm={12} md={4}>
              <Box className="pro-con cons">
                <Typography className="pro-con-header">Cons</Typography>
                <List className="pro-con-list" dense disablePadding>
                  <ListItem className="pro-con-list-item" disableGutters>
                    <ListItemIcon className="pro-con-icon">
                      <CloseIcon fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <>
                          <strong>API Limit:</strong> Can exceed API limits for
                          very large accounts
                        </>
                      }
                    />
                  </ListItem>
                  <ListItem className="pro-con-list-item" disableGutters>
                    <ListItemIcon className="pro-con-icon">
                      <CloseIcon fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <>
                          <strong>Fewer scanning options:</strong> No EBS
                          volume-based scanning or organization-wide scanning
                        </>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="mode-detail">
          <Grid container spacing={2}>
            <Grid item sm={12} md={4}>
              <Typography className="mode-detail-name">Serverless</Typography>
              <Typography className="mode-detail-description">
                Serverless integrations scan AWS accounts and organizations with
                our secure, Lambda-based scanning agent.{" "}
                <Link
                  href="https://mondoo.com/docs/platform/infra/cloud/aws/lambda/integration-lambda/"
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  Learn more.
                </Link>
              </Typography>
              <Button
                className="mode-detail-button"
                color="primary"
                variant="contained"
                endIcon={<ChevronRightIcon fontSize="inherit" />}
                onClick={() => setValue("integrationType", "serverless")}
              >
                Select serverless integration
              </Button>
            </Grid>
            <Grid item sm={12} md={4}>
              <Box className="pro-con pros">
                <Typography className="pro-con-header">Pros</Typography>
                <List className="pro-con-list" dense disablePadding>
                  <ListItem className="pro-con-list-item" disableGutters>
                    <ListItemIcon className="pro-con-icon">
                      <CheckIcon fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <>
                          <strong>Highest security:</strong> Scan from within
                          your environment without giving Mondoo access
                        </>
                      }
                    />
                  </ListItem>
                  <ListItem className="pro-con-list-item" disableGutters>
                    <ListItemIcon className="pro-con-icon">
                      <CheckIcon fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <>
                          <strong>Stability:</strong> Avoid API limits in large
                          environments
                        </>
                      }
                    />
                  </ListItem>
                  <ListItem className="pro-con-list-item" disableGutters>
                    <ListItemIcon className="pro-con-icon">
                      <CheckIcon fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <>
                          <strong>Triggered scans:</strong> Automatically
                          trigger scans when instances change
                        </>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid item sm={12} md={4}>
              <Box className="pro-con cons">
                <Typography className="pro-con-header">Cons</Typography>
                <List className="pro-con-list" dense disablePadding>
                  <ListItem className="pro-con-list-item" disableGutters>
                    <ListItemIcon className="pro-con-icon">
                      <CloseIcon fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <>
                          <strong>Complexity:</strong> Requires installation
                          into your environment
                        </>
                      }
                    />
                  </ListItem>
                  <ListItem className="pro-con-list-item" disableGutters>
                    <ListItemIcon className="pro-con-icon">
                      <CloseIcon fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <>
                          <strong>Cost:</strong> Scans incur a small AWS cost
                        </>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );

  const hostedContent = (
    <>
      {roleAuthEnabled && (
        <Box pb={4}>
          <Command
            number={3}
            options={{
              fontSize: { xs: 16 },
              dotColor: theme.palette.background.lightest,
            }}
          >
            Choose your authentication method
          </Command>
          <Box sx={{ mt: 3 }}>
            <Controller
              key="hosted.authMethod"
              name="hosted.authMethod"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Box>
                    <FormControlLabel
                      value="key"
                      control={<Radio />}
                      label="Key"
                    />
                    <Typography
                      variant="caption"
                      display="block"
                      color="text.secondary"
                      sx={{ pl: 4, mt: -1 }}
                    >
                      Use your AWS access key ID and AWS secret access key to
                      authenticate
                    </Typography>
                  </Box>
                  <Box>
                    <FormControlLabel
                      value="arn"
                      control={<Radio />}
                      label="ARN"
                    />
                    <Typography
                      variant="caption"
                      display="block"
                      color="text.secondary"
                      sx={{ pl: 4, mt: -1 }}
                    >
                      Use your assumed role ARN and optional external ID
                    </Typography>
                  </Box>
                </RadioGroup>
              )}
            />
          </Box>
        </Box>
      )}

      <Box pb={4}>
        <Command
          number={roleAuthEnabled ? 4 : 3}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Enter authentication details
        </Command>
        {hostedAuthMethod === "key" && (
          <Box>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 2, mt: 2 }}
            >
              To learn more about the required access keys, read the{" "}
              <Link
                href="https://mondoo.com/docs/platform/infra/cloud/aws/hosted/integration-hosted/"
                target="_blank"
              >
                Mondoo documentation
              </Link>
              .
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Controller
                key="hosted.accessKeyId"
                name="hosted.accessKeyId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      ".MuiInputBase-root": {
                        background: theme.palette.code.background,
                      },
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="Access Key ID"
                    error={Boolean(errors.hosted?.accessKeyId)}
                    helperText={
                      Boolean(errors.hosted?.accessKeyId) && (
                        <ValidationMessage error={errors.hosted?.accessKeyId} />
                      )
                    }
                  />
                )}
              />

              <Controller
                key="hosted.secretAccessKey"
                name="hosted.secretAccessKey"
                control={control}
                rules={{
                  validate: {
                    required: (value) => {
                      if (getFieldState("hosted.accessKeyId").isDirty && !value)
                        return "Required.";
                      return true;
                    },
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      ".MuiInputBase-root": {
                        background: theme.palette.code.background,
                      },
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    type="password"
                    placeholder="AWS Secret Access Key"
                    error={Boolean(errors.hosted?.secretAccessKey)}
                    helperText={
                      Boolean(errors.hosted?.secretAccessKey) && (
                        <ValidationMessage
                          error={errors.hosted?.secretAccessKey}
                        />
                      )
                    }
                  />
                )}
              />
            </Box>
          </Box>
        )}
        {hostedAuthMethod === "arn" && (
          <Box>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 2, mt: 2 }}
            >
              To learn more about the required assumed role, read the{" "}
              <Link
                href="https://mondoo.com/docs/platform/infra/cloud/aws/hosted/integration-hosted/"
                target="_blank"
              >
                Mondoo documentation
              </Link>
              .
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Controller
                key="hosted.arn"
                name="hosted.arn"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      ".MuiInputBase-root": {
                        background: theme.palette.code.background,
                      },
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="Assumed Role ARN"
                    error={Boolean(errors.hosted?.arn)}
                    helperText={
                      Boolean(errors.hosted?.arn) && (
                        <ValidationMessage error={errors.hosted?.arn} />
                      )
                    }
                  />
                )}
              />

              <Controller
                key="hosted.externalId"
                name="hosted.externalId"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      ".MuiInputBase-root": {
                        background: theme.palette.code.background,
                      },
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="AWS External ID (optional)"
                    error={Boolean(errors.hosted?.externalId)}
                    helperText={
                      Boolean(errors.hosted?.externalId) && (
                        <ValidationMessage error={errors.hosted?.externalId} />
                      )
                    }
                  />
                )}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );

  const serverlessContent = (
    <>
      <>
        <Box pb={4}>
          <Command
            number={3}
            options={{
              fontSize: { xs: 16 },
              dotColor: theme.palette.background.lightest,
            }}
          >
            Select organization or single account install
          </Command>
          <Box sx={{ mt: 3 }}>
            <Controller
              key="serverless.installMethod"
              name="serverless.installMethod"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Box>
                    <FormControlLabel
                      value="account"
                      control={<Radio />}
                      label="Single account install"
                      disabled={Boolean(updateFlow)}
                    />
                    <Typography
                      variant="caption"
                      display="block"
                      color="text.secondary"
                      sx={{ pl: 4, mt: -1 }}
                    >
                      Install the CloudFormation stack in a single AWS account.
                    </Typography>
                  </Box>
                  <Box>
                    <FormControlLabel
                      value="organization"
                      control={<Radio />}
                      label="Organization install"
                      disabled={Boolean(updateFlow)}
                    />
                    <Typography
                      variant="caption"
                      display="block"
                      color="text.secondary"
                      sx={{ pl: 4, mt: -1 }}
                    >
                      Use CloudFormation StackSets to install the AWS
                      integration to your entire AWS Organization or
                      organizational units.
                    </Typography>
                  </Box>
                </RadioGroup>
              )}
            />
          </Box>
        </Box>

        <Box pb={4}>
          <Command
            number={4}
            options={{
              fontSize: { xs: 16 },
              dotColor: theme.palette.background.lightest,
            }}
          >
            Select installation options
          </Command>
          <Box sx={{ mt: 3 }}>
            <Box display="flex" gap={2} mt={3}>
              <Box flex={1}>
                <Typography fontWeight={700}>Select region</Typography>
                <Typography variant="body2" color="text.secondary">
                  This determines where to install the Mondoo integration; it
                  does not determine which region to scan.
                </Typography>
              </Box>
              <Box flex={1} display="flex" justifyContent="flex-end">
                <Controller
                  key="serverless.region"
                  name="serverless.region"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      sx={{
                        "&.MuiInputBase-root": {
                          bgcolor: "code.background",
                        },
                      }}
                    >
                      {AWS_REGIONS.map((region) => (
                        <MenuItem key={region} value={region}>
                          {region}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Box>
            </Box>

            <Box>
              <Box gap={2} mt={3}>
                <Typography fontWeight={700}>Select VPC</Typography>
                <Typography variant="body2" color="text.secondary">
                  Choose which VPC to use when scanning.
                </Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Controller
                  key="serverless.vpcType"
                  name="serverless.vpcType"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <Box>
                        <FormControlLabel
                          value="default"
                          control={<Radio />}
                          label="AWS default VPC"
                        />
                        <Typography
                          variant="caption"
                          display="block"
                          color="text.secondary"
                          sx={{ pl: 4, mt: -1 }}
                        >
                          Only choose this option if your selected region has a
                          default VPC.
                        </Typography>
                      </Box>
                      <Box>
                        <FormControlLabel
                          value="mondoo"
                          control={<Radio />}
                          label="Mondoo-created VPC"
                        />
                        <Typography
                          variant="caption"
                          display="block"
                          color="text.secondary"
                          sx={{ pl: 4, mt: -1 }}
                        >
                          Have Mondoo create a dedicated VPC.
                        </Typography>
                      </Box>
                    </RadioGroup>
                  )}
                />
              </Box>

              {serverlessVpcType === "mondoo" && (
                <Box display="flex" gap={2} mt={3}>
                  <Box flex={1}>
                    <Typography fontWeight={700}>Configure CIDR</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Enter an IPv4 address range for the VPC Mondoo creates. To
                      learn more, read the{" "}
                      <Link
                        href="https://docs.aws.amazon.com/vpc/latest/userguide/vpc-cidr-blocks.html"
                        target="_blank"
                        rel="noopener"
                      >
                        AWS documentation
                      </Link>
                      .
                    </Typography>
                  </Box>
                  <Box flex={1} display="flex" justifyContent="flex-end">
                    <Controller
                      key="serverless.vpcCidr"
                      name="serverless.vpcCidr"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          sx={{
                            ".MuiInputBase-root": {
                              background: theme.palette.code.background,
                            },
                            borderRadius: 1,
                            color: "text.primary",
                            ...helperTextStyles,
                          }}
                          error={Boolean(errors.serverless?.vpcCidr)}
                          helperText={
                            Boolean(errors.serverless?.vpcCidr) && (
                              <ValidationMessage
                                error={errors.serverless?.vpcCidr}
                              />
                            )
                          }
                        />
                      )}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </>
      <Box pb={4}>
        <Command
          number={5}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Select scan options
        </Command>
        <Box>
          <Box display="flex" gap={2} mt={3}>
            <Box flex={1}>
              <Typography fontWeight={700}>Schedule full scan</Typography>
              <Typography variant="body2" color="text.secondary">
                Trigger a full scan of the AWS account resources and EC2
                instances across all regions, independent of change events.
              </Typography>
            </Box>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <Controller
                key="serverless.cronScanInHours"
                name="serverless.cronScanInHours"
                control={control}
                rules={{ required: "Required. Must be a number." }}
                render={({ field }) => (
                  <TextField
                    type="number"
                    label="Interval in Hours"
                    // prevent a user from inputing anything that is not a number
                    onKeyDown={(event) => {
                      // allow a user to delete and select all
                      const validKeys = ["Backspace", "Delete", "Tab"];
                      const validCombo =
                        event.key === "a" && (event.ctrlKey || event.metaKey);
                      if (
                        !Validations.isNumber.test(event.key) &&
                        !validKeys.includes(event.key) &&
                        !validCombo
                      ) {
                        event.preventDefault();
                      }
                    }}
                    {...field}
                    fullWidth
                    sx={{
                      ".MuiInputBase-root": {
                        background: theme.palette.code.background,
                      },
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    error={Boolean(errors.serverless?.cronScanInHours)}
                    helperText={
                      Boolean(errors.serverless?.cronScanInHours) && (
                        <ValidationMessage
                          error={errors.serverless?.cronScanInHours}
                          integrationTypeId="aws-serverless"
                        />
                      )
                    }
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box pb={4}>
        <Command
          number={6}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Select EC2 options
        </Command>
        <Box>
          <Box display="flex" gap={2} mt={3}>
            <Box flex={1}>
              <Typography fontWeight={700}>Discover EC2 instances</Typography>
              <Typography variant="body2" color="text.secondary">
                Include EC2 instances in asset discovery.
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Controller
                key="serverless.discoverEc2Instances"
                name="serverless.discoverEc2Instances"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    control={<Switch checked={field.value} />}
                    label={actionText(field.value)}
                    labelPlacement="start"
                  />
                )}
              />
            </Box>
          </Box>
          <Box display="flex" gap={2} mt={3}>
            <Box flex={1}>
              <Typography fontWeight={700}>
                Trigger on instance state change event
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Scan new EC2 instances upon creation.
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Controller
                key="serverless.triggerOnInstanceStateChange"
                name="serverless.triggerOnInstanceStateChange"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    control={<Switch checked={field.value} />}
                    label={actionText(field.value)}
                    labelPlacement="start"
                    disabled={!serverlessDiscoverEc2Instances}
                  />
                )}
              />
            </Box>
          </Box>
          <Box display="flex" gap={2} mt={3}>
            <Box flex={1}>
              <Typography fontWeight={700}>
                Use SSM for instance connectivity
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Use AWS SSM service to trigger scans on EC2 instances with an
                online SSM agent.
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Controller
                key="serverless.useSsm"
                name="serverless.useSsm"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    control={<Switch checked={field.value} />}
                    label={actionText(field.value)}
                    labelPlacement="start"
                    disabled={!serverlessDiscoverEc2Instances}
                  />
                )}
              />
            </Box>
          </Box>
          <Box display="flex" gap={2} mt={3}>
            <Box flex={1}>
              <Typography fontWeight={700}>
                Use EC2 Instance Connect for instance connectivity
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Use AWS EC2 Instance Connect service to trigger scans on EC2
                instances with public IPs.
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Controller
                key="serverless.useEc2InstanceConnect"
                name="serverless.useEc2InstanceConnect"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    control={<Switch checked={field.value} />}
                    label={actionText(field.value)}
                    labelPlacement="start"
                    disabled={!serverlessDiscoverEc2Instances}
                  />
                )}
              />
            </Box>
          </Box>
          <Box display="flex" gap={2} mt={3}>
            <Box flex={1}>
              <Typography fontWeight={700}>
                Use EBS volume scanning for instance scanning
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Use EBS volume scanning to perform filesystem scans of EC2
                instances (no credentialed access required).
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Controller
                key="serverless.useEbsVolumeScanning"
                name="serverless.useEbsVolumeScanning"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    control={<Switch checked={field.value} />}
                    label={actionText(field.value)}
                    labelPlacement="start"
                    disabled={!serverlessDiscoverEc2Instances}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box pb={4}>
        <Command
          number={7}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Select EC2 filtering options
        </Command>
        <Box>
          <Box display="flex" gap={2} mt={3}>
            <Box flex={1}>
              <Typography fontWeight={700}>Filter by instance IDs</Typography>
              <Typography variant="body2" color="text.secondary">
                Filter by instance ID, e.g., i-0d1f840599ca987f0,
                i-07ae83fe5d2b984ba.
              </Typography>
            </Box>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <Controller
                key="serverless.filterInstanceIds"
                name="serverless.filterInstanceIds"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      ".MuiInputBase-root": {
                        background: theme.palette.code.background,
                      },
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    error={Boolean(errors.serverless?.filterInstanceIds)}
                    helperText={
                      Boolean(errors.serverless?.filterInstanceIds) && (
                        <ValidationMessage
                          error={errors.serverless?.filterInstanceIds}
                        />
                      )
                    }
                    placeholder="Instance IDs to include (optional)"
                    disabled={!serverlessDiscoverEc2Instances}
                  />
                )}
              />
            </Box>
          </Box>
          <Box display="flex" gap={2} mt={3}>
            <Box flex={1}>
              <Typography fontWeight={700}>Filter by regions</Typography>
              <Typography variant="body2" color="text.secondary">
                Filter by region, e.g., us-east-1, us-west-2.
              </Typography>
            </Box>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <Controller
                key="serverless.filterRegions"
                name="serverless.filterRegions"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      ".MuiInputBase-root": {
                        background: theme.palette.code.background,
                      },
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    error={Boolean(errors.serverless?.filterRegions)}
                    helperText={
                      Boolean(errors.serverless?.filterRegions) && (
                        <ValidationMessage
                          error={errors.serverless?.filterRegions}
                        />
                      )
                    }
                    placeholder="Regions to include (optional)"
                    disabled={!serverlessDiscoverEc2Instances}
                  />
                )}
              />
            </Box>
          </Box>
          <Box display="flex" gap={2} mt={3}>
            <Box flex={1}>
              <Typography fontWeight={700}>Filter by tags</Typography>
              <Typography variant="body2" color="text.secondary">
                Filter by tags, e.g., Name:testname, env:test.
              </Typography>
            </Box>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <Controller
                key="serverless.filterTags"
                name="serverless.filterTags"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      ".MuiInputBase-root": {
                        background: theme.palette.code.background,
                      },
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    error={Boolean(errors.serverless?.filterTags)}
                    helperText={
                      Boolean(errors.serverless?.filterTags) && (
                        <ValidationMessage
                          error={errors.serverless?.filterTags}
                        />
                      )
                    }
                    placeholder="Tags to include (optional)"
                    disabled={!serverlessDiscoverEc2Instances}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box pb={4}>
        <Command
          number={8}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Select ECS and ECR options
        </Command>
        <Box>
          <Box display="flex" gap={2} mt={3}>
            <Box flex={1}>
              <Typography fontWeight={700}>
                Discover and scan ECS containers
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Use ECS Exec to scan Fargate containers.
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Controller
                key="serverless.discoverEcsContainers"
                name="serverless.discoverEcsContainers"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    control={<Switch checked={field.value} />}
                    label={actionText(field.value)}
                    labelPlacement="start"
                  />
                )}
              />
            </Box>
          </Box>
          <Box display="flex" gap={2} mt={3}>
            <Box flex={1}>
              <Typography fontWeight={700}>
                Discover and scan container images
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Include ECR images in asset discovery.
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Controller
                key="serverless.discoverEcrImages"
                name="serverless.discoverEcrImages"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    control={<Switch checked={field.value} />}
                    label={actionText(field.value)}
                    labelPlacement="start"
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );

  const content = (() => {
    switch (integrationType) {
      case "hosted":
        return hostedContent;
      case "serverless":
        return serverlessContent;
      case "unknown":
        return chooseContent;
    }
  })();

  return (
    <Box>
      <IntegrationAddHeader {...{ type: IntegrationType.Aws }} />
      <form onSubmit={handleSubmit(onSubmit)}>
        {integrationType !== "unknown" && formContent}
        {content}
        {integrationType !== "unknown" && (
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<ChevronRightIcon />}
              disabled={!isValid}
            >
              {updateFlow ? "update configuration" : "start scanning"}
            </Button>
          </Box>
        )}
      </form>
    </Box>
  );
}
